export default defineNuxtRouteMiddleware((_to, _from) => {
  const toast = useToast()

  const user = useSupabaseUser()
  if (!user.value) {
    toast.add({
      title: 'You dont have permission to access this page',
      color: 'red',
    })
    return navigateTo('/')
  }
})
